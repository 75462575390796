import React, { useState, useEffect } from "react";
import './Map.less';
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import mapBanner from "../../../../assets/about/map_banner.png";

interface MapProps {
	count?: number;
	language: string;
}

const boxVariant = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};

const Map: React.FC<MapProps> = ({ count = 0, language }) => {
  	const { t } = useTranslation();

	const control = useAnimation();
    	const [ref, inView] = useInView();
     	useEffect(() => {
	      if (inView) {
	        control.start("visible");
	      } else {
	        control.start("hidden");
	      }
	    }, [control, inView]);

    return(
     <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
      >
        <div className="mapContainer">
                <div className="mapContainerWrap">
                    <img src={mapBanner} alt="" className="mapContainer__mapBanner" />
		            <i className="light__iconGreen" style={{top: '20%', left: '20%'}}></i>
		            <i className="light__iconGreen" style={{top: '25%', left: '25%'}}></i>
		            <i className="light__iconGreen" style={{top: '24%', left: '30%'}}></i>
		            <i className="light__iconGreen" style={{top: '70%', left: '30%'}}></i>
		            <i className="light__iconGreen" style={{top: '20%', left: '20%'}}></i>
		            <i className="light__iconGreen" style={{top: '25%', right: '25%'}}></i>
		            <i className="light__iconGreen" style={{top: '24%', right: '30%'}}></i>
		            <i className="light__iconGreen" style={{top: '20%', right: '45%'}}></i>
		            <i className="light__iconGreen" style={{top: '30%', right: '40%'}}></i>
		            <i className="light__iconGreen" style={{top: '35%', right: '35%'}}></i>
		            <i className="light__iconGreen" style={{top: '65%', right: '45%'}}></i>
		            <i className="light__iconGreen" style={{top: '45%', right: '43%'}}></i>
		            <i className={count % 2 === 0 ? "light__iconGreen light": "light__iconGreen"} style={{top: '30%', left: '20%', width: 50, height: 50}}></i>

		            <i className={count % 3 === 0 ? "light__iconWhite light": "light__iconWhite"} style={{top: '28%', right: '18%'}}></i>
		            <i className={count % 2 === 0 ? "light__iconWhite light": "light__iconWhite"} style={{top: '40%', right: '19%'}}></i>

		             <i className={count % 3 === 0 ? "light__iconGolden light": "light__iconGolden"} style={{top: '42%', right: '18%'}}></i>
{/*
		             <span className="text__pos" style={{top: '33%', right: '14%'}} >South Korea</span>
		             <span className="text__pos" style={{top: '48%', right: '16%'}} >Taiwan</span>
		             <span className="text__pos" style={{top: '41%', right: '21%'}} >Shen Zhen</span>
		             <span className="text__pos" style={{top: '47%', right: '20%'}} >Hong Kong</span>
		              <span className="text__pos" style={{top: '40%', left: '22%'}} >North America</span>*/}
		             <div className="mapContainer__title">
		             	 {t("home.intro_title")}
		             </div>
		        </div>
             <div className={language === 'en' ? "mapContainer__footer en" : "mapContainer__footer" }> 
			 {t("about.company_shenzhen")} | {t("about.company_1")} | {t("about.company_2")} |  {t("about.company_3")} | {t("about.company_4")} 
              </div>

        </div>
       </motion.div>
    )
}

export default Map;